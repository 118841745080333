import { prepareDatetimes } from '~/api/helpers'

export function searchPosts (data) {
  return apiFetch('/blogs', { params: data }).then(prepareDatetimes)
}

export function getTags () {
  return apiFetch('/blog-tags')
}

export function getPost (slug) {
  return apiFetch(`/blogs/${slug}`).then(prepareDatetimes)
}
