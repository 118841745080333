<template>
  <article class="post">
    <NuxtLink class="post__image-link" :to="to" target="_blank">
      <SlicesHover class="post__image-container">
        <img :src="post.image.url" :alt="post.title" class="post__image">
      </SlicesHover>
    </NuxtLink>
    <ChipList :items="chips" class="post__tags" not-clickable />
    <div class="post__info">
      <h2 class="post__title"><NuxtLink :to="to" target="_blank">{{ post.title }}</NuxtLink></h2>
      <div class="post__date"><time :datetime="post.created_at">{{ dayjs(post.created_at).format('D.MM.YYYY') }}</time></div>
      <LinkButton class="post__link" :to="to" target="_blank">Подробнее<IconArrowRight class="icon" /></LinkButton>
    </div>
  </article>
</template>

<script setup>
import { IconArrowRight } from '@tabler/icons-vue'

const props = defineProps({
  post: Object
})

const dayjs = useDayjs()

const chips = computed(() => [props.post.mentor ? 'От ментора' : 'От редакции'].concat(props.post.tags))
const to = computed(() => ({ name: 'blog-post', params: { slug: props.post.slug ?? props.post.id } }))
</script>

<style scoped lang="scss">
.post {
  &__image-link {
    opacity: 1 !important;
  }
  &__image-container {
    height: 315px;
    border-radius: 10px;

    @media (max-width: 920px) {
      height: 219px;
    }
  }
  &__tags {
    margin-top: 24px;
    :deep(.chip) {
      border-width: 1px;
    }
  }
  &__info {
    margin-top: 24px;
  }
  &__title {
    font-size: 24px;
    font-weight: 800;
    line-height: 28px;

    @media (max-width: 920px) {
      font-size: 20px;
      line-height: 27px;
    }

    > :deep(a) {
      opacity: 1 !important;
      transition: .2s color;
      &:hover {
        color: var(--color-primary);
      }
    }
  }
  &__date {
    margin-top: 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
  &__link {
    margin-top: 16px;
  }

  @media (max-width: 920px) {
    margin-bottom: 8px !important;
    grid-column: span 2;
    border: none;
    padding: 15px !important;
    border-radius: 0;
    background: var(--color-bg);
  }
}
</style>
